<template>
    <div id="service">
        <div class="intro">
            <h2>Community Matters</h2>
            <p>
                CZRE is proud to give back to our community.
            </p>
        </div>
        <div class="communities">
            <div class="container" v-for="(item, i) in communities" :key="i">
                <img class="big-logo image" :src="item.img" :alt="item.text">
                <div class="description overlay">
                    <p>
                        {{item.text}}
                    </p>
                    <a :href="item.url" target="_blank" rel="noopener noreferrer">
                        <img class="small-logo" :src="item.img" :alt="item.text">
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.intro{
    text-align: center;
    h2{
        text-transform: uppercase;
        font-size: 60px;
        margin: 60px 20px;
    }
    p{
        font-size: 20px;
    }
}
.communities{
    display: flex;
    margin: 60px auto;
    flex-wrap: wrap;
    gap: 1%;
    row-gap: 40px;
    justify-content: center;
}

.container {
  position: relative;
  width: 32.33%;
  min-height: 440px;
  background: #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  max-height: 600px;
}

.image {
  display: block;
  height: 40%;
  width: auto;
  max-width: 80%;
}

.small-logo{
    width: 100px;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #f2673a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container:hover .overlay {
  opacity: 1;
}

.description {
    text-align: center;
    color: white;
    p{
        margin: 40px 20px; 
    }
}


@media (max-width: 1269px) {
    .communities{
        gap: 3%;
        row-gap: 40px;
    }
    .container {
        width: 48.5%
    }
}

@media (max-width: $mobile) {
    .communities{
        gap: 0;
        row-gap: 60px;
    }
    .container {
        width: 100%;
        height: 80vh;
        max-height: 600px;
    }
    .image {
        max-height: 200px;
    }
    .intro{
        h2{
            font-size: 42px;
            line-height: 36px;
        }
        margin: 0 20px;
    }
}
</style>

<script>
// import PageTitle from "../components/PageTitle"

export default {
    name: "Test",
    // components: {
    //     PageTitle
    // },
    metaInfo: {
        title: 'Community Matters',
        titleTemplate: '%s | Cerda-Zein',
        meta: [{
            name: 'description', 
            content: "Cerda-Zein"
        }]
    },
    data(){
        return{
            communities:[
                {
                    img: '/images/logos/donut_fun_run.webp',
                    text: "The Donut Fun Run is a family-friendly, donut-themed 5k race at Alameda Point's Seaplane Lagoon Promenade. Participants can enjoy a fun-filled post-race Donut Festival featuring live music, entertainment, and delicious treats from Donut Petit. Proceeds from the event will support the Alameda Boys & Girls Club, helping to provide impactful programs that empower local youth to reach their full potential.",
                    url: 'https://thedonutfunrun.com/'
                },
                {
                    img: '/images/logos/little_league.jpeg',
                    text: 'Alameda Little League is a community organization driven by dedicated volunteers whose primary purpose is to provide an outlet for healthful activity and training under positive leadership. The objective of the League shall be to instill firmly in the children of Alameda the ideals of good sportsmanship, honesty, loyalty, courage and respect for authority, so that they can be well adjusted, stronger and happier children and grow to be good, decent, healthy and trustworthy citizens.Our Board of Directors serve as volunteers and receive no compensation for their time.',
                    url: 'https://www.alamedalittleleague.org/'
                },
                {
                    img: '/images/logos/agsa.png',
                    text: 'Alameda Girls Softball Association (AGSA) provides recreational and tournament fastpitch softball programs for girls age 4 to 14 with the overall goal of developing sportsmanship, teamwork, friendship and leadership. AGSA is the only youth sports league in Alameda with a primary focus on development of girls in sports. AGSA is affiliated with NorCal USA Softball.',
                    url: 'https://www.alamedagsa.com/'
                },
                {
                    img: '/images/logos/asc.jpeg',
                    text: 'The Alameda Soccer Club is located in Alameda, California. Alameda is a city of 75,000 in San Francisco Bay, adjacent to Oakland and about 10 miles from San Francisco. Our soccer club fields more than 100 teams with over 1,400 kids from the ages of four and one-half to nineteen. Membership is open to all. The mission of the Alameda Soccer Club is to provide the opportunity for all children to learn and play the game of soccer.',
                    url: 'https://www.alamedasoccer.org/about-asc/'
                },
                {
                    img: '/images/logos/AYB_Logo.webp',
                    text: 'Alameda Youth Basketball, Inc. (“AYB”), a 501(c)(3) non-profit corporation, is going strong after 27 years of providing both recreational and competitive basketball to boys and girls, grades three through eight.  AYB’s Mission is to organize and operate a basketball league for elementary and middle school boys and girls to foster the spirit of good sportsmanship, competition, teamwork, leadership, physical fitness and an understanding of the game of basketball.  The league is nondiscriminatory and shall not deny for financial reasons the right of any otherwise eligible person to participate.',
                    url: 'https://alamedayouthbasketball.club/'
                },
                {
                    img: '/images/logos/Alameda_High_School_Athletic_Boosters.png',
                    text: 'Alameda High School Athletic Boosters is a volunteer run organization of parents and community members fundraising and working to support the AHS Athletic Program.',
                    url: 'https://stjosephalameda.org/'
                },
                {
                    img: '/images/logos/st-joseph.png',
                    text: 'The St. Joseph Elementary School is a ministry of the St. Joseph Basilica Catholic Church. The school is one of thirty-nine elementary schools in the Diocese of Oakland. The school began as Notre Dame Academy in 1881, a primary and secondary all-girls school staffed by the Sisters of Notre Dame. In that same year in support of the academy, the St. Joseph Mission Church moved to its present location at San Antonio Avenue and Chestnut Street. The church would later become St. Joseph Basilica.',
                    url: 'https://ahsathleticboosters.com/about-us/'
                },
            ]
        }
    },
}
</script>